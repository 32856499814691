export default function renderFootnotes() {
    const $container = $('#wiki_body_container');
    let fnoteList = "";
    $('.fnoteWrap .fnoteBtn').each((index, el) => {
        const text = $(el).text().replaceAll('[', '').replaceAll(']', '');
        fnoteList += "<li class='fnote-item' id='footnote-" + text + "'>" + decodeURIComponent($(el).data('content') )+ "</li>";
    });
    if (fnoteList) {
        fnoteList =
            "<h4>Footnotes</h4>\n" +
            "<ol>" + fnoteList;
        fnoteList += "</ol>"
        $container.append(fnoteList);
    }
    $(".fnote-link").on("click", function (event) {
        $(".fnote-item").css("background-color", "");
        $(event.currentTarget.getAttribute('href')).css("background-color", "#bcf5bc");
    });
}

